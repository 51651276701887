import {CashIncomeActionTypes} from './constants';

export type CashIncomeActionType = {
    type:
        | CashIncomeActionTypes.API_RESPONSE_SUCCESS
        | CashIncomeActionTypes.API_RESPONSE_ERROR
        | CashIncomeActionTypes.API_RESPONSE_VALIDATION_ERRORS
        | CashIncomeActionTypes.RESET
        | CashIncomeActionTypes.ORDERS
        | CashIncomeActionTypes.ORDER
        | CashIncomeActionTypes.CREATE
        | CashIncomeActionTypes.UPDATE
        | CashIncomeActionTypes.SEARCH
        | CashIncomeActionTypes.REVIEW
        | CashIncomeActionTypes.CANCEL
        | CashIncomeActionTypes.UPDATE_SETTING
        | CashIncomeActionTypes.GET_SETTING;

    payload: {} | string;
};

type CashIncome = {
    id: number;
    name: string;
};

class CashIncomeActions {
    apiResponseSuccess = (actionType: string, data: CashIncome | {}): CashIncomeActionType => ({
        type: CashIncomeActionTypes.API_RESPONSE_SUCCESS,
        payload: { actionType, data },
    });

    apiResponseError = (actionType: string, error: string, trackId: string = ""): CashIncomeActionType => ({
        type: CashIncomeActionTypes.API_RESPONSE_ERROR,
        payload: { actionType, error, trackId },
    });

    apiResponseValidationErrors = (actionType: string, validationErrors: [], trackId: string = ""): CashIncomeActionType => ({
        type: CashIncomeActionTypes.API_RESPONSE_VALIDATION_ERRORS,
        payload: { actionType, validationErrors, trackId },
    });

    reset = (): CashIncomeActionType => ({
        type: CashIncomeActionTypes.RESET,
        payload: {},
    });

    getCashIncomes = (queryParams: any): CashIncomeActionType => ({
        type: CashIncomeActionTypes.ORDERS,
        payload: { queryParams },
    });

    getCashIncome = (queryParams: any): CashIncomeActionType => ({
        type: CashIncomeActionTypes.ORDER,
        payload: { queryParams },
    });

    create = (data: any): CashIncomeActionType => ({
        type: CashIncomeActionTypes.CREATE,
        payload: { data },
    });

    update = (data: any): CashIncomeActionType => ({
        type: CashIncomeActionTypes.UPDATE,
        payload: { data },
    });

    search = (queryParams: any): CashIncomeActionType => ({
        type: CashIncomeActionTypes.SEARCH,
        payload: { queryParams },
    });

    review = (data: any): CashIncomeActionType => ({
        type: CashIncomeActionTypes.REVIEW,
        payload: { data },
    });

    cancel = (hashedId: string): CashIncomeActionType => ({
        type: CashIncomeActionTypes.CANCEL,
        payload: { hashedId },
    });

    updateSetting = (data: any, trackId: string): CashIncomeActionType => ({
        type: CashIncomeActionTypes.UPDATE_SETTING,
        payload: { data, trackId },
    });

    getSetting = (): CashIncomeActionType => ({
        type: CashIncomeActionTypes.GET_SETTING,
        payload: {},
    });

}

const cashIncomeActions = new CashIncomeActions();
export { cashIncomeActions };
