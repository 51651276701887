import {IntlPaymentActionTypes} from './constants';

export type IntlPaymentActionType = {
    type:
        | IntlPaymentActionTypes.API_RESPONSE_SUCCESS
        | IntlPaymentActionTypes.API_RESPONSE_ERROR
        | IntlPaymentActionTypes.API_RESPONSE_VALIDATION_ERRORS
        | IntlPaymentActionTypes.RESET
        | IntlPaymentActionTypes.ORDERS
        | IntlPaymentActionTypes.ORDER
        | IntlPaymentActionTypes.CREATE
        | IntlPaymentActionTypes.UPDATE
        | IntlPaymentActionTypes.SEARCH
        | IntlPaymentActionTypes.REVIEW
        | IntlPaymentActionTypes.CANCEL
        | IntlPaymentActionTypes.UPDATE_SETTING
        | IntlPaymentActionTypes.GET_SETTING;

    payload: {} | string;
};

type IntlPayment = {
    id: number;
    name: string;
};

class IntlPaymentActions {
    apiResponseSuccess = (actionType: string, data: IntlPayment | {}): IntlPaymentActionType => ({
        type: IntlPaymentActionTypes.API_RESPONSE_SUCCESS,
        payload: { actionType, data },
    });

    apiResponseError = (actionType: string, error: string, trackId: string = ""): IntlPaymentActionType => ({
        type: IntlPaymentActionTypes.API_RESPONSE_ERROR,
        payload: { actionType, error, trackId },
    });

    apiResponseValidationErrors = (actionType: string, validationErrors: [], trackId: string = ""): IntlPaymentActionType => ({
        type: IntlPaymentActionTypes.API_RESPONSE_VALIDATION_ERRORS,
        payload: { actionType, validationErrors, trackId },
    });

    reset = (): IntlPaymentActionType => ({
        type: IntlPaymentActionTypes.RESET,
        payload: {},
    });

    getIntlPayments = (queryParams: any): IntlPaymentActionType => ({
        type: IntlPaymentActionTypes.ORDERS,
        payload: { queryParams },
    });

    getIntlPayment = (queryParams: any): IntlPaymentActionType => ({
        type: IntlPaymentActionTypes.ORDER,
        payload: { queryParams },
    });

    create = (data: any): IntlPaymentActionType => ({
        type: IntlPaymentActionTypes.CREATE,
        payload: { data },
    });

    update = (data: any): IntlPaymentActionType => ({
        type: IntlPaymentActionTypes.UPDATE,
        payload: { data },
    });

    search = (queryParams: any): IntlPaymentActionType => ({
        type: IntlPaymentActionTypes.SEARCH,
        payload: { queryParams },
    });

    review = (data: any): IntlPaymentActionType => ({
        type: IntlPaymentActionTypes.REVIEW,
        payload: { data },
    });

    cancel = (hashedId: any): IntlPaymentActionType => ({
        type: IntlPaymentActionTypes.CANCEL,
        payload: { hashedId },
    });

    updateSetting = (data: any, trackId: string): IntlPaymentActionType => ({
        type: IntlPaymentActionTypes.UPDATE_SETTING,
        payload: { data, trackId },
    });

    getSetting = (): IntlPaymentActionType => ({
        type: IntlPaymentActionTypes.GET_SETTING,
        payload: {},
    });
}

const intlPaymentActions = new IntlPaymentActions();
export { intlPaymentActions };
