import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {BillActionTypes, BillErrors} from "./constants";
import {billActions} from "./actions";
import {graphql} from "../../helpers";
import {SagaIterator} from "@redux-saga/core";
import {checkResponseError, checkServerError, pluckResponse} from "../../helpers/functions";

type BillData = {
    payload: {
        id: string;

        limit: string;
        page: string;

        queryParams: {
            limit: string;
            page: string;
            billBy: string;
            sort: string;
        };

        data: any;
    };
    type: string;
};

function* bills({ payload: { queryParams } }: BillData): SagaIterator {
    try {
        const param = {
            query:`query Bills(
                $page: Int!,
                $limit: Int!,
                $billBy: String!,
                $sort: String!,
            ) {
                bills(
                    page: $page,
                    limit: $limit,
                    billBy: $billBy,
                    sort: $sort,
                ) {
                    data {
                        hashedId
                        created_at
                        amount
                        rate
                        name
                        final_amount
                        status
                    }
                    total
                    per_page
                    from
                    to
                    current_page
                    last_page
                    has_more_pages
                    
                }
            }`,

            variables: {
                ...queryParams
            }
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, BillErrors.RESPONSE_200);

        const rBill = response.data;

        checkServerError(rBill);

        const bills = pluckResponse(rBill, "bills");

        yield put(billActions.apiResponseSuccess(BillActionTypes.BILLS, bills));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(billActions.apiResponseError(BillActionTypes.BILLS, error));
        } else {
            yield put(billActions.apiResponseValidationErrors(BillActionTypes.BILLS, error));
        }
    }
}

function* bill({ payload: { queryParams } }: BillData): SagaIterator {
    try {
        const param = {
            query:`query Bill($hashedId: String!) {
                bill(hashedId: $hashedId) {
                    hashedId
                    created_at
                    amount
                    currency
                    user {
                        username
                    }
                    model {
                        hashedId
                    }
                    amount
                    expire_date
                    status
                    description
                }
            }`,

            variables: {
                ...queryParams
            }
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, BillErrors.RESPONSE_200);

        const rBill = response.data;

        checkServerError(rBill);

        const bill = pluckResponse(rBill, "bill");

        yield put(billActions.apiResponseSuccess(BillActionTypes.BILL, bill));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(billActions.apiResponseError(BillActionTypes.BILL, error));
        } else {
            yield put(billActions.apiResponseValidationErrors(BillActionTypes.BILL, error));
        }
    }
}


function* payViaGateway({ payload: { data } }: BillData): SagaIterator {
    try {
        const param = {
            query:`mutation PayBillViaGateway($hashedId: String!, $bankCard: String!, $method: String!) {
                payBillViaGateway(hashedId: $hashedId, bankCard: $bankCard, method: $method) 
            }`,

            variables: {
                ...data
            }
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, BillErrors.RESPONSE_200);

        const rBill = response.data;

        checkServerError(rBill);

        const bill = pluckResponse(rBill, "payBillViaGateway");

        yield put(billActions.apiResponseSuccess(BillActionTypes.PAY_VIA_GATEWAY, bill));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(billActions.apiResponseError(BillActionTypes.PAY_VIA_GATEWAY, error));
        } else {
            yield put(billActions.apiResponseValidationErrors(BillActionTypes.PAY_VIA_GATEWAY, error));
        }
    }
}

function* payViaWallet({ payload: { data } }: BillData): SagaIterator {
    try {
        const param = {
            query:`mutation PayBillViaWallet($hashedId: String!) {
                payBillViaWallet(hashedId: $hashedId) 
            }`,

            variables: {
                ...data
            }
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, BillErrors.RESPONSE_200);

        const rBill = response.data;

        checkServerError(rBill);

        pluckResponse(rBill, "payBillViaWallet");

        yield put(billActions.apiResponseSuccess(BillActionTypes.PAY_VIA_WALLET, true));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(billActions.apiResponseError(BillActionTypes.PAY_VIA_WALLET, error));
        } else {
            yield put(billActions.apiResponseValidationErrors(BillActionTypes.PAY_VIA_WALLET, error));
        }
    }
}

export function* watchBills() {
    yield takeEvery(BillActionTypes.BILLS, bills);
}

export function* watchBill() {
    yield takeEvery(BillActionTypes.BILL, bill);
}

export function* watchPayViaGateway() {
    yield takeEvery(BillActionTypes.PAY_VIA_GATEWAY, payViaGateway);
}

export function* watchPayViaWallet() {
    yield takeEvery(BillActionTypes.PAY_VIA_WALLET, payViaWallet);
}


function* billSaga() {
    yield all([
        fork(watchBills),
        fork(watchBill),
        fork(watchPayViaGateway),
        fork(watchPayViaWallet),
    ]);
}

export default billSaga;
